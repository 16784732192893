import {LocaleDataModel} from '@ui/schemes/app/locale.model'

const locale: LocaleDataModel = {
  main: {
    title: 'Тренажер',
    overall_stat: {
      correct: 'Правильных ответов',
      incorrect: 'Неправильных ответов',
      points: 'Баллов',
    },
    banner: {
      title: 'Проходи тесты<br>и закрепляй знания',
      btn: 'Подробнее',
    },
    info_modal: {
      title: 'Что такое<br>«Тренажер»?',
      message: 'Тренажер – это место, где вы можете прокчать свои знания по определенным предметам и темам. Выполняйте тестовые задания на любые темы по 5-ти основным предметам и закрепяйте пройденный материал. Удачи!',
    },
    results: {
      title: 'Результат недели',
      empty: 'Нет данных',
      today_correct_answers: 'Сегодня: {{count}} правильных ответа(-ов)',
    },
    subjects: {
      title: 'Список предметов',
    },
  },
  subthemes: {
    select_subtheme: 'Выберите тему',
    empty_theme_list: 'По этому предмету еще нет вопросов. Наша команда уже работает над ними.',
    progress: 'Прогресс: {{progress}}%',
    test_finished: 'Тест завершен!',
    finished: 'Выполнено',
    restart: 'Заново',
    restart_modal: {
      title: 'Вы действительно хотите начать тему заново?',
      confirm: 'Начать заново',
      cancel: 'Отмена',
    },
  },
  subthemes_filter: {
    title: 'Выбор темы',
  },
  star_gain_success: {
    title: 'Поздравляем!',
    message: 'Вы ответили на 20 правильных вопросов и получили сегодня 1 звезду',
  },
}

export default locale
